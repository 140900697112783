<template>
  <base-horizontal-container
    :loading="isLoading"
    :loading-next="isLoadingNext!==null?isLoadingNext:isLoadingNextPage"
    :loading-previous="isLoadingPrevious!==null?isLoadingPrevious:isLoadingPreviousPage"
    :items="value ? results : events"
    :item-class="itemClass"
    :initial-index="initialIndex"
    :total="total"
    :actual-page="actualPage"
    :placeholder="EventsPlaceholder"
    :placeholder-message="
      $t('available.message', { itemName: $t('events.title') })
    "
    @load-next="handleLoadOfNextEvents"
    @load-previous="handleLoadOfPreviousEvents"
    @update:scrollable="$emit('update:scrollable', $event)"
  >
    <template #default="slotProps">
      <slot v-bind="slotProps" />
    </template>
    <template #footer="slotProps">
      <slot name="footer" v-bind="{ ...slotProps, updateEvent }" />
    </template>
  </base-horizontal-container>
</template>

<script>
import EventsPlaceholder from '@/assets/images/placeholders/light/events.svg';

import BaseHorizontalContainer from '@core/components/containers/base/BaseHorizontalContainer.vue';

/**
 * The load events must return an objecft with a startDate, all other is optional.
 */
export default {
  name: 'BaseEventsHorizontalList',
  components: { BaseHorizontalContainer },
  props: {
    reload: Number,
    loadNextPage: {
      type: Function,
      default: () => [],
    },
    loadPreviousPage: {
      type: Function,
      default: () => [],
    },
    eventsType: {
      type: Object,
      default: () => {},
    },
    itemClass: {
      type: [String, Object, Array],
      default: '',
    },
    forcedEvents: {
      type: Array,
      default: null,
    },
    customfields: {
      default: null,
    },
    classifiers: {
      default: null,
    },
    forceEvents: {
      type: Boolean,
      default: false,
    },
    isLoadingNext: {
      type: Boolean,
      default: null,
    },
    isLoadingPrevious: {
      type: Boolean,
      default: null,
    },
    room: {
      type: Object,
      default: null,
    },
    value: String,
  },
  data() {
    return {
      results: [],
      initialIndex: null,
      isLoading: true,
      isLoadingPreviousPage: false,
      isLoadingNextPage: false,
    };
  },
  computed: {
    currentCollectiveSlug() {
      return this.$store.getters.currentCollective.slug;
    },
    lastDate() {
      return this.events[this.events.length - 1]?.startDate || null;
    },
    firstDate() {
      return this.events[0]?.startDate || null;
    },
    itemsData() {
      if (this.room && Object.values(this.room).length > 0) {
        return this.$store.getters.communitiesOthers[`${this.room.key}-events`];
      }
      if (this.eventsType?.key > 0) {
        return this.$store.getters.communitiesOthers[this.eventsType.name];
      }
      return this.$store.getters.communitiesOthers.events;
    },
    events() {
      if (this.forcedEvents && this.itemsData) {
        return this.itemsData.unpaginated;
      }
      return [];
    },
    total() {
      if (this.itemsData) return this.itemsData.meta.total;
    },
    actualPage() {
      if (this.itemsData) return this.itemsData.meta.current_page;
    },
  },
  watch: {
    value(newVal, oldVal) {
      this.searchEvent();
    },
    async forceEvents() {
      await Promise.all([
        this.handleLoadOfNextEvents(),
        this.handleLoadOfPreviousEvents(),
      ]);
    },
    async reload() {
      await Promise.all([
        this.handleLoadOfNextEvents(),
        this.handleLoadOfPreviousEvents(),
      ]);
    },
  },
  setup() {
    return { EventsPlaceholder };
  },
  async mounted() {
    if (!this.forcedEvents) {
      this.isLoading = true;
      await Promise.all([
        this.handleLoadOfNextEvents(),
        // this.handleLoadOfPreviousEvents(),
      ]);
    }
    this.isLoading = false;
    await this.$nextTick();

    const now = Date.now();
    let a = 0;
    for (let i = 1; i < this.events.length; i++) {
      if (
        Math.abs(new Date(this.events[i]?.startDate) - now)
          < Math.abs(new Date(this.events[a]?.startDate) - now)
        && Math.abs(new Date(this.events[i].startDate) - now) >= 0
      ) {
        a = i;
      }
    }
    this.initialIndex = a;
  },
  methods: {
    searchEvent() {
      const searchTerm = this.value.toLowerCase().trim();
      if (!searchTerm) {
        return;
      }
      this.results = this.events.filter((item) => Object.values(item.title)[0].toLowerCase().includes(searchTerm));
    },
    async handleLoadOfNextEvents() {
      if (this.forcedEvents) {
        this.$emit('scrollOnePage', +1);
        return;
      }
      this.isLoadingNextPage = true;
      const items = await this.loadNextPage(+1, {
        firstDate: this.firstDate,
        lastDate: this.lastDate,
      });
      this.events.push(...items);
      this.isLoadingNextPage = false;
      this.$emit('events-updated', this.events);
    },
    async handleLoadOfPreviousEvents() {
      if (this.forcedEvents) {
        this.$emit('scrollOnePage', -1);
        return;
      }
      this.isLoadingPreviousPage = true;
      const items = await this.loadPreviousPage(-1, {
        firstDate: this.firstDate,
        lastDate: this.lastDate,
      });
      this.events.unshift(...items);
      this.isLoadingPreviousPage = false;
      this.$emit('events-updated', this.events);
    },
    updateEvent(event) {
      const index = this.events.findIndex(({ key }) => key === event.key);
      if (index !== -1) {
        this.$set(this.events, index, event);
      }
    },
  },
};
</script>
