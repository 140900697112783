<template>
  <div class="float-right">
    <div class="d-flex align-items-center" style="height: 2rem">
      <span
        v-if="itemTypesPermissions ? showList : true"
        class="d-flex align-items-center"
      >
        <!-- Search -->
        <div 
          v-if="isSearchNeeded" 
          class="d-flex"
        >
          <!-- Search input -->
          <b-form-input
            v-model="searchInput"
            type="text"
            class="mr-2"
            style="width: 250px"
            :hidden="showSearchInput ? false : true"
            :placeholder="$t('form.type-placeholder')"
            @input="updateInput"
          />
          <!-- Search icon button -->
          <div
            id="search-tooltip"
            class="text-primary"
            @click="toggleSearch"
          >
            <feather-icon
              v-b-tooltip.hover
              :title="$t('tooltips.widget-actions.search')"
              icon="SearchIcon"
              variant="primary"
              size="20"
            />
          </div>
        </div>

        <!-- Filter  -->
        <div
          v-if="showFilter"
          id="filter-tooltip"
          v-b-tooltip.hover
          :title="$t('tooltips.widget-actions.filter')"
          class="text-primary ml-1"
          @click="toggleFilters"
        >
          <b-button variant="link" class="p-0 toggle-filters-button">
            <b-icon-sliders />
          </b-button>
        </div>
      </span>

      <!-- Create New Item -->
      <div
        v-if="showAdd"
        id="create-item"
        v-b-tooltip.hover
        :title="$t('tooltips.widget-actions.add')"
        class="text-primary create-item"
        :class="isSearchNeeded || showFilter ? 'ml-1' : '' "
        @click="openModal"
      >
        <feather-icon icon="PlusIcon" variant="primary" size="20" />
      </div>

      <!-- Create New Folder -->
      <div
        v-if="showCreateFolder"
        id="create-folder"
        v-b-tooltip.hover
        :title="$t('tooltips.widget-actions.new-folder')"
        class="text-primary ml-1 create-item"
        @click="folderModal"
      >
        <feather-icon icon="FolderPlusIcon" variant="primary" size="20" />
      </div>

      <!-- Download Button -->
      <div
        v-if="showDownload"
        id="download-item"
        class="text-primary ml-1"
        @click="downloadModal"
      >
        <feather-icon icon="DownloadCloudIcon" variant="primary" size="20" />
      </div>

      <div v-if="showAdd != undefined && showAdd !== true" />
      <!-- Open list -->
      <!-- TODO: Roles only implemented in challenges -->
      <div
        v-if="itemTypesPermissions ? showList : true"
        class="text-primary"
      >
        <feather-icon
          v-if="hasExpandAction"
          id="maximize-tooltip"
          v-b-tooltip.hover
          :title="$t('tooltips.widget-actions.extend')"
          icon="MaximizeIcon"
          variant="primary"
          size="20"
          class="ml-1"
          style="cursor: pointer"
          @click="location"
        />
        <b-link v-else-if="showMaximize" :to="itemTypeRoute" class="ml-1">
          <feather-icon
            v-b-tooltip.hover
            :title="$t('tooltips.widget-actions.show')"
            icon="MaximizeIcon"
            variant="primary"
            size="20"
          />
        </b-link>
        <div
          v-else-if="showListType"
          id="view-type-tooltip"
          class="text-primary ml-1 "
          @click="toggleListType"
        >
          <b-button variant="link" class="p-0 toggle-filters-button">
            <b-icon-list-ul v-if="isListTypeCard" />
            <b-icon-grid v-else />
          </b-button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { ENABLED_APPS_GETTERS } from '@/store/enabled-apps/enabled-apps-store-constants';

export default {
  name: 'WidgetActions',
  props: {
    itemType: { type: String, required: true },
    showAdd: { type: Boolean, default: true },
    showCreateFolder: { type: Boolean, default: false },
    showDownload: { type: Boolean, default: false },
    showList: { type: Boolean, default: true },
    showFilter: { type: Boolean, default: false },
    isSearchNeeded: { type: Boolean, default: true },
    isMedia: { type: Boolean, default: false },
    type: { type: String },
    showMaximize: { type: Boolean, default: true },
    showListType: { type: Boolean, default: false },
    isListTypeCard: { type: Boolean, default: true },
    size: { type: Number, required: false },
    addComponent: { type: String, required: false },
    typeName: {
      type: String,
      default: '',
    },
    auditorium: { type: Boolean, default: null },
  },
  data() {
    return {
      searchInput: '',
      showSearchInput: false,
      areFiltersVisible: true,
      isListTypeVisible: true,
    };
  },
  computed: {
    hasExpandAction() {
      const hasExpansionAction = ['map', 'auditorium', 'faqs', 'agenda'];
      return hasExpansionAction.includes(this.itemType);
    },
    itemTypeRoute() {
      if (this.itemType === 'community-members') {
        return { name: this.itemType, params: { type: this.type } };
      }
      if (this.itemType === 'entity') {
        return { name: this.itemType, params: { typeKey: this.type } };
      }
      if (this.itemType === 'people') {
        return { name: this.itemType, params: { typeKey: this.type } };
      }
      if (this.itemType === 'events' && this.type) {
        return { name: this.itemType, query: { display: this.type } };
      }
      if (this.itemType === 'challenges' && this.type) {
        return { name: this.itemType, query: { display: this.type } };
      }
      return { name: this.itemType };
    },
    isEditingLayout() {
      return this.$store.getters[ENABLED_APPS_GETTERS.isEditingLayout];
    }
  },
  methods: {
    updateInput(value) {
      this.$emit('updateSearch', this.searchInput);
    },
    toggleSearch() {
      this.showSearchInput = !this.showSearchInput;
      if (!this.showSearchInput && this.searchInput !== '') {
        this.searchInput = '';
        this.$emit('updateSearch', this.searchInput);
      }
    },
    openModal() {
      // console.log('Open modal!');
      if (this.addComponent != null) {
        this.$router.push({
          name: this.addComponent,
          params: { communityId: this.$store.getters.currentCollective?.slug },
        });
        return;
      }
      // console.log('this.isMedia', this.isMedia);
      if (this.isMedia === true) {
        return this.$emit('openMediaModal');
      }
      const modalName = this.typeName
        ? `modal-create-${this.itemType}-${this.typeName}`
        : `modal-create-${this.itemType}`;
        
      // console.log('modalName', modalName);
      this.$bvModal.show(modalName);
      this.$emit('openModal');
    },
    folderModal() {
      this.$emit('openFolderModal');
    },
    downloadModal() {
      this.$emit('openDownloadModal', true);
    },
    toggleFilters() {
      this.$emit('toggleFilters', this.areFiltersVisible);
    },
    toggleSearchContact() {
      this.$emit('toggleSearchs', true);
    },
    toggleListType() {
      this.$emit('toggleListType', this.isListTypeVisible);
    },
    itemTypesPermissions() {
      return (
        this.itemType === 'challenges'
        || this.itemType === 'events'
        || this.itemType === 'services'
        || this.itemType === 'products'
        || this.itemType === 'projects'
        || this.itemType === 'success-stories'
        || this.itemType === 'ideas'
        || this.itemType === 'community-members'
      );
    },
    location() {
      return this.$router.push({ name: this.itemType });
    },
  },
};
</script>
<style scoped>
#search-tooltip {
  cursor: pointer;
}
.create-item {
  cursor: pointer;
}
</style>
