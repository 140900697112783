<template>
  <div>
    <b-card
      :key="compKey"
      class="event card small-card small-card-hover p-0 overflow-hidden "
      :class="item.isPublished ? '' : 'no-published'"
      :style="style"
    >
      <!--       <b-link :href="getCollectiveUrl(item)" class="h-100">
 -->
      <b-link
        :to="{ name: 'event', params: { slug: item.slug, communityId: $route.params.communityId } }"
        class="h-100"
      >
        <div class="event--container w-100">
          <!--           <div class="event--buttons w-100 p-1 d-flex justify-content-end align-items-center">
            <feather-icon
              v-b-tooltip.hover.bottom
              icon="Share2Icon"
              :title="$t('share.tooltip')"
              size="18"
              class="p-0 mr-1 text-light"
              @click.stop.prevent="isShareModalVisible = true"
            />
            <div class="text-light">
              <feather-icon
                size="18"
                :class="item.likedByMember ? 'text-primary' : ''"
                class="profile-icon"
                icon="HeartIcon"
                @click.stop.prevent="like(item)"
              />

              {{ item.totalLikes }}
            </div>
          </div> -->

          <div class="event--text pt-4 w-100 px-1">
            <p v-if="item.type" class="w-100 mb-0 font-small-3 text-muted font-weight-400 text-ellipsis-lines-1">
              {{ translate(item.type)[0].toUpperCase() + translate(item.type).substring(1) }}
            </p>
            <p v-else class="w-100 mb-0 small text-muted text-ellipsis-lines-1">
              No type
            </p>
            <p class="w-100 text-light html-text-ellipsis-2 mb-1 font-medium-3 h4" style="min-height: 43.50px">
              {{ translate(item.name) }}
            </p>
            <!--            <p class="w-100 font-weight-200 headline text-light min-height-2 text-ellipsis-lines-1 mb-25">
              {{ item.headline }}
            </p> -->
            <b-card-group v-if="item.startDate" class="mb-25">
              <b-card-text
                v-if="item.endDate==null||(item.endDate && dayDate(item.endDate) === dayDate(item.startDate))"
                class="text-white text-cap-first mb-25"
              >
                <p class="mb-0 h5 font-weight-600 font-medium-1 text-white">
                  {{ item.startDate | moment('ddd DD MMM YYYY') }}
                </p>

                <p class="mb-0 font-weight-bold text-white font-small-4">
                  {{ item.startDate | moment('HH:mm') }} h  ({{ timezone(item.startDate) }})
                </p>
                <!-- {{ item.startDate | moment('ddd DD MMM YYYY - HH:mm') }} h {{ timezone(item.startDate) }} -->
              </b-card-text>
              <b-card-text v-else class="text-white text-cap-first mb-25">
                <p class="mb-0 h5 font-weight-600 font-medium-1 text-white">
                  {{ item.startDate | moment('ddd DD') }} - {{ item.endDate | moment('DD MMM YYYY') }}
                </p>

                <p class="mb-0 font-weight-bold text-white font-small-4">
                  {{ item.startDate | moment('HH:mm') }} h  ({{ timezone(item.startDate) }})
                </p>
              </b-card-text>
            </b-card-group>
            <!-- <b-card-group v-else class="mb-25">
              <b-card-text class="text-secondary mb-0">
                {{ $t('events.event-details.no-dates-yet') }}
              </b-card-text>
            </b-card-group> -->

            <div class="d-flex mb-25">
              <b-card-group class="mb-0 mr-3">
                <b-card-text v-if="item.modality === 'online'" class="text-light small">
                  <b-icon-camera-video font-scale="1.2" class="mr-50" />
                  {{ item.modality != null ? $t(`events.event-details.${item.modality}`) : null }}
                </b-card-text>
                <b-card-text v-else class="text-light small">
                  <b-icon-geo-alt font-scale="1.2" class="mr-25" />
                  {{ item.modality != null ?$t(`events.event-details.${item.modality}`) : null }}
                </b-card-text>
              </b-card-group>
              <b-card-group v-if="item.showPrice !== false" class="m-0 text-secondary text-light small d-flex align-items-center">
                <feather-icon icon="DollarSignIcon" size="15" class="mr-50" />
                <b-card-text v-if="item.hasPrice">
                  {{ $t('events.list.no-free') }}
                </b-card-text>
                <b-card-text v-else>
                  {{ $t('events.list.free') }}
                </b-card-text>
              </b-card-group>
            </div>
            <b-card-group v-if="item.poweredBy" class="d-flex align-items-center flex-nowrap m-0">
              <b-avatar
                size="16"
                :src="getImageSrc(item.poweredBy.logoURL)"
                :alt="translate(item.poweredBy.name)"
                class="user-avatar mr-50"
              />

              <b-card-text class="text-muted html-text-ellipsis-1 small">
                {{ translate (item.poweredBy.name) }}
              </b-card-text>
            </b-card-group>
          </div>
        </div>
      </b-link>
    </b-card>
  </div>
</template>

<script>
import { getImageResource } from '@/@core/utils/image-utils';
import Placeholder from '@/assets/images/placeholders/light/placeholder-dark.jpeg';
import { enumToInvertedObject, randomString } from '@core/utils/utils';
import { EventType } from '@copernicsw/community-common';
import { checkPermissions } from '@/@core/utils/roles-utils';
import { getCollectiveUrl } from '@/@core/utils/collective';
import { DateTime } from 'luxon';
import { translateTranslationTable } from '@/@core/libs/i18n/utils';

export default {
  name: 'EventItem',
  props: {
    item: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      isShareModalVisible: false,
      compKey: randomString(10),
      style: `background: url('${this.item.logoURL || this.item.bgURL || this.imagePlaceholder}'); background-size: cover`,
    };
  },
  computed: {
    canShare() {
      return checkPermissions('share', 'events', this.loggedMemberRoles, this.$store.getters.currentCollective);
    },
    imagePlaceholder() {
      return Placeholder;
    },
  },

  methods: {
    translate(field) {
      return translateTranslationTable(this.$store.state.locale.currentLocale, field);
    },
    getCollectiveUrl(item) {
      return getCollectiveUrl(item);
    },
    getImageSrc(url) {
      return getImageResource(url);
    },
    currentLocale() {
      return this.$store.state.locale.currentLocale;
    },
    isStaff() {
      return this.$store.getters.currentCollective.isStaff;
    },
    dayDate(date) {
      const dateOptions = {
        weekday: 'short',
        month: 'long',
        day: 'numeric',
      };
      const eventDate = new Date(date);
      return eventDate.toLocaleDateString('es-ES', dateOptions);
    },
    eventType(type) {
      if (type) {
        return this.type.en;
      }
      return 'No type';
    },
    like(item) {
      if (item.likedByMember) {
        item.likedByMember = false;
        item.totalLikes--;
      } else {
        item.likedByMember = true;
        item.totalLikes++;
      }
      this.toggleLike(item);
    },
    timezone(timestamp) {
      return DateTime.fromISO(timestamp).offsetNameShort;
    },
  },
};
</script>

<style lang="scss" scoped>
.event {
  height: 22em;
  width: 22em;
  background-repeat: no-repeat;
  background-position: center;
  border-radius: 0.8em;
  border: 0;
  position: relative;

  &--container {
    height: 100%;
    background: linear-gradient(transparent, rgba(0, 0, 0, 0.9));
  }
  &--text {
    height: 75%;
    position: absolute;
    bottom: 0;
  }
}
.headline {
  font-weight: 200 !important;
}
.no-published {
  filter: grayscale(100%);
  opacity: 0.6;
  border-color: black;
  border-style: dashed !important;
  border-width: 3px !important;
}
</style>
