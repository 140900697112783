<template>
  <list-container-widget
    id="events"
    :app-id="96"
    :title="type.key === 0 ? widgetName: translate(type.namePlural) "
    :type="type.key === 0 ? null : `${type.key}`"
    stretch
    class="d-flex"
  >
    <!-- Panel actions -->
    <template #actions>
      <!-- CUIDADO!!!!! NO BORRAR  -->
      <div ref="target" />
      <!-- <div ref="target" style="width: 5px; height: 5px; background-color: black;" /> -->
      <ViewAllButton item-type="events" />
    </template>

    <template>
      <!-- Loading -->
      <b-card-body class="x-scroll-container">
        <div v-if="isLoading">
          <b-spinner small class="mx-auto mt-3 d-block" />
        </div>

        <!-- Container with data -->
        <div v-else-if="items && items.length > 0" class="cards-container">
        <!-- <div v-else-if="testItems.length" class="cards-container"> -->
          <div
            ref="container"
            class="x-scroll-container w-100 py-0"
            @scroll="onScroll"
          >
            <!-- Items Loop -->
            <!-- <ul>
              <li v-for="item in testItems" :key="item.id">
                {{ item.id }}
              </li>
            </ul> -->
            <base-events-horizontal-list
              v-slot="{ item }"
              :key="reload"
              :forced-events="items"
              :value="searchInput"
              :reload="reload"
              :is-loading-next="isLoadingNextPage"
              :is-loading-previous="isLoadingPreviousPage"
              :events-type="type"
              @events-updated="$emit('events-updated', $event)"
              @scrollOnePage="scrollOnePage"
            >
              <event-item :key="item.key" :item="item" />
            </base-events-horizontal-list>
          </div>
        </div>

        <b-row v-else class="horizontal-placeholder">
          <b-col cols="12">
            <img :src="eventsPlaceholder" alt="Events with members">
          </b-col>
          <b-col cols="12">
            <p class="text-primary">
              {{ $t("available.message", { itemName: widgetName }) }}
            </p>
          </b-col>
        </b-row>
      </b-card-body>
    </template>

    <!-- Create item Modal -->
    <b-modal
      v-if="!modalAlreadyExistsById"
      id="modal-create-events" centered hide-footer size="lg"
    >
      <template #modal-header>
        <language-selector-header 
          :title="$t('events.actions.create-event')" 
          @closeModal="closeModal" 
          @selectLanguage="(language)=>selectedLanguage = language" 
        />
      </template>
      <membership-event-create-modal
        item-type="events"
        @close-modal="closeModal"
        :selected="selectedLanguage"
      />
    </b-modal>
  </list-container-widget>
</template>

<script>
import { getImageResource } from '@/@core/utils/image-utils';
import ListContainerWidget from '@core/widgets/ListContainerWidget.vue';
import WidgetActions from '@/@core/components/widget-actions/WidgetActions.vue';
import { quillEditor } from 'vue-quill-editor';
import moment from 'moment';
import WidgetLayoutMixin from '@core/mixins/widgets/WidgetLayoutMixin';
import vSelect from 'vue-select';
import FileTypes from '@/@core/constants/FileTypes';
import { checkPermissions } from '@/@core/utils/roles-utils';
import EventsPlaceholder from '@/assets/images/placeholders/light/events.svg';
import { translateTranslationTable } from '@/@core/libs/i18n/utils';
import BaseEventsHorizontalList from '@/views/apps/events/components/BaseEventsHorizontalList.vue';
import LanguageSelectorHeader from '@core/components/modal/LanguageSelectorHeader.vue';
import EventItem from '../components/EventItem.vue';
import { ref } from '@vue/composition-api';
import { useElementVisibility } from '@vueuse/core'
import MembershipEventCreateModal from "@/views/apps/membershipEvents/components/MembershipEventCreateModal.vue";

export default {
  name: 'MembershipEventsListWidget',
  
  setup() {
    const target = ref(null)
    const targetIsVisible = useElementVisibility(target)

    return {
      target,
      targetIsVisible,
    }
  },

  components: {
    ListContainerWidget,
    WidgetActions,
    quillEditor,
    vSelect,
    EventItem,
    BaseEventsHorizontalList,
    LanguageSelectorHeader,
    MembershipEventCreateModal,
    ViewAllButton: () => import ("@/@core/components/widget-actions/ViewAllButton.vue"  /* webpackChunkName: "ViewAllButton" */),
  },

  mixins: [WidgetLayoutMixin],

  props: {
    type: {
      type: Object,
      required: true,
    },
  },

  data() {
    return {
      // testItems: [],
      isLoading: false,
      isLoadingTypes: false,
      isLoadingNextPage: false,
      isLoadingPreviousPage: false,
      lastLoadedPage: 1,
      loadingNext: false,
      selectedLanguage: this.currentLocale,
      formInput: {},
      itemImage: null,
      imageSrc: null,
      searchInput: '',
      results: [],
      perPage: 20,
      typeOptions: [],
      // scroll widget
      forceEvents: false,
      reload: 0,
    };
  },

  computed: {
    modalAlreadyExistsById() {
      return document.getElementById("modal-create-events");
    },
    FileTypes() {
      return FileTypes;
    },
    collective() {
      return this.$store.getters.currentCollective;
    },
    currentLocale() {
      return this.$store.state.locale.currentLocale;
    },
    isStaff() {
      return this.$store.getters.currentCollective.isStaff;
    },
    // Load data from store
    itemsData() {
      let events = [];
      if (this.type?.key > 0) {
        events = this.$store.getters.communitiesOthers[this.type.name]?.unpaginated;
      } else {
        events = this.$store.getters.communitiesOthers.events?.unpaginated;
      }
      if (!events || !events.length) {
        return []
      }
      return events?.filter((item) => item.isPublished || (!item.isPublished && this.canAdd));
    },
    items() {
      const itemsToIterate = this.searchInput
        ? this.results
        : this.itemsData;
      return itemsToIterate;
    },
    loggedUser() {
      return this.$store.getters.loggedUser;
    },
    loggedMemberRoles() {
      return this.$store.getters.loggedMemberRoles;
    },
    canAdd() {
      return (
        this.loggedUser.key === this.item?.ownedByUser?.key
        || this.isStaff
        || checkPermissions(
          'create',
          'event',
          this.loggedMemberRoles,
          this.collective,
        )
      );
    },
    eventsPlaceholder() {
      return EventsPlaceholder;
    },
  },

  watch: {
    targetIsVisible (newValue) {
      // console.log("++++++++++++ --> WATCH!!! MembershipEventsListWidget.vue (targetIsVisible): ", newValue)
      if (newValue && !this.itemsData.length) {
        // console.log("2 --> WATCH!!! MembershipEventsListWidget.vue (targetIsVisible): ", newValue)
        this.loadAllData()
      }
    },

    itemImage(newValue, oldValue) {
      if (newValue !== oldValue) {
        if (newValue) {
          this.base64Encode(newValue)
            .then((value) => {
              this.imageSrc = value;
            })
            .catch(() => {
              this.imageSrc = null;
            });
        } else {
          this.imageSrc = null;
        }
      }
    },
  },

  // Fetch data when component is created!
  // async created() {
  //   this.isLoading = true;
  //   await this.fetchData(true);
  //   // await this.fetchClassifiers();
  //   this.isLoading = false;
  // },

  methods: {
    async loadAllData () {
      this.isLoading = true;
      await this.fetchData();
      // await this.fetchClassifiers();
      this.isLoading = false;
    },

    translate(field) {
      return translateTranslationTable(this.currentLocale, field);
    },
    getImageSrc(url) {
      return getImageResource(url);
    },
    timestampToTime(timestamp) {
      return moment(timestamp).format('DD-MM-YYYY');
    },
    base64Encode(data) {
      return new Promise((resolve, reject) => {
        const reader = new FileReader();
        reader.readAsDataURL(data);
        reader.onload = () => resolve(reader.result);
        reader.onerror = (error) => reject(error);
      });
    },
    closeModal() {
      this.$bvModal.hide('modal-create-events');
    },

    // Call store action (with dispach) to load data from backend
    async fetchData(force = false) {
      const requestConfig = {
        communityParentSlug: this.$store.getters.currentCollective.slug,
        orderByDate: 1,
        isEvent: true,
        displayOptions: this.type?.key,
      };
      await this.$store.dispatch('getItems', {
        itemType: 'communities/simply',
        customName: 'communitiesOthers',
        storedKey: this.type?.key > 0 ? this.type.name : 'events',
        page: this.lastLoadedPage,
        perPage: this.perPage,
        forceAPICall: force,
        requestConfig,
      });

      // const { data } = await getCommunitiesSimply({ params: {
      //   page: this.lastLoadedPage,
      //   count: this.perPage,
      //   communityParentSlug: requestConfig.communityParentSlug,
      //   orderByDate: 1,
      //   isEvent: true,
      //   displayOptions: requestConfig.displayOptions,
      //   communitySlug: this.$route.params.communityId
      //   // page: 1
      //   // count: 100
      //   // communityParentSlug: tu-comunidad
      //   // orderByDate: 1
      //   // isEvent: true
      //   // displayOptions: 4
      //   // communitySlug: tu-comunidad
      // } })
      // if (data.data.length) {
      //   // this.testItems = data.filter((item) => item.isPublished || (!item.isPublished && this.canAdd));
      //   this.testItems = data.data
      // }
      // console.log("RESPONSE(data.data): ", data.data)
      // console.log("RESPONSE(testItems): ", this.testItems)
    },

    // TESTING !!!!!! BORRAR
    // var observer = new IntersectionObserver(function(entries) {
    //   // isIntersecting is true when element and viewport are overlapping
    //   // isIntersecting is false when element and viewport don't overlap
    //   if(entries[0].isIntersecting === true)
    //     console.log('Element has just become visible in screen');
    // }, { threshold: [0] });

    // observer.observe(document.querySelector("#main-container"));
    // FINAL TESTING !!!!!! BORRAR

    // When the scroll reaches the end, load more data.
    async onScroll(event) {
      const distanceToEnd = event.scrollWidth
        - event.scrollLeft
        - event.offsetWidth;
      if (distanceToEnd < 50 && !this.isLoadingNextPage) {
        this.handleLoadOfNewItems();
      }
    },
    // Load more items
    async handleLoadOfNewItems() {
      const { total } = this.itemsData.meta;
      if (!this.isLoading && this.items.length < total) {
        this.lastLoadedPage += 1;
        this.isLoadingNextPage = true;
        await this.fetchData(false);
        this.isLoadingNextPage = false;
      }
    },
    // Scroll forward and back (buttons)
    scrollOnePage(direction) {
      const currentScroll = this.$refs.container?.scrollLeft;
      const toScroll = this.$refs.container?.offsetWidth;
      this.$scrollTo(this.$refs.container, 500, {
        container: this.$refs.container,
        duration: 500,
        offset: currentScroll + direction * toScroll,
        x: true,
        y: false,
        onDone: (event) => {
          this.onScroll(event);
        },
      });
    },
    searchItems(value) {
      this.searchInput = value;
      const searchTerm = value.toLowerCase().trim();
      if (!searchTerm) {
        return;
      }
      this.results = this.itemsData.unpaginated.filter((item) => item.name.toLowerCase().trim().includes(searchTerm));
    },
    // async getEventTypes() {
    //   this.isLoadingTypes = true;
    //   const response = await this.$store.dispatch('getItems', {
    //     itemType: 'types',
    //     storedKey: 'event',
    //     page: 1,
    //     perPage: 50,
    //     forceAPICall: true,
    //     requestConfig: { 
    //       modelType: 'event', 
    //       getCommunityTypes: 1 
    //     },
    //   });
    //   if (response) {
    //     this.typeOptions = [];
    //     if(response.data){
    //       for (const typeOption of response.data) {
    //         Object.values(typeOption.name).length > 0
    //           ? this.typeOptions.push({
    //             code: typeOption.id,
    //             type: this.translate(typeOption.name),
    //           })
    //           : '';
    //       }
    //     }
    //   }
    //   this.isLoadingTypes = false;
    // },
  },
};
</script>

<style scoped>
.card-img {
  height: 200px !important;
}
</style>
